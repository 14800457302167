import React, { Component } from "react";
import { Link } from "gatsby";

import { createUser, getUserOnboardingData } from "api/db";
import * as routes from "constants/routes";

import { doCreateUserWithEmailAndPassword } from "api/auth";

import styled from "styled-components";

import { navigate } from "gatsby";

import SignUpWithGoogle from "../OAuth/Google";
import SignUpWithGithub from "../OAuth/Github";

import { Button, AuthContainer } from "../OAuth/styles";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  created_at: "",
  error: null,
  showForm: false,
  cohort: false,
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.state.created_at = new Date().toString();
    if (this.props.cohort) {
      this.setState({ cohort: this.props.cohort });
    }
  }

  signUp = (event) => {
    const { username, email, passwordOne, created_at } = this.state;

    const { history } = this.props;

    doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        let photoURL = "/img/avatar.png";
        createUser(authUser.user.uid, username, email, created_at, photoURL)
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));

            if (!this.props.cohort) {
              navigate(HOME);
              // getUserOnboardingData(user.uid).then(snapshot => {
              //   if (snapshot.val() != null) {
              //     if (
              //       snapshot.val().interest != null &&
              //       snapshot.val().motivation != null &&
              //       snapshot.val().experience != null
              //     ) {
              //       navigate(HOME);
              //     } else {
              //       navigate(ONBOARDING);
              //     }
              //   } else {
              //     navigate(ONBOARDING);
              //   }
              // });
            } else {
              if (typeof window != undefined) {
                setTimeout(function() {
                  window.location.reload(false);
                }, 1000);
              }
            }
          })
          .catch((error) => {
            this.setState(updateByPropertyName("error", error));
          });
      })
      .catch((error) => {
        this.setState(updateByPropertyName("error", error));
      });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      showForm,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      username === "" ||
      email === "";

    return (
      <>
        {this.state.cohort ? (
          <div className="flex flex-col text-center w-full mt-4">
            <h2 className="text-base text-gray-700 tracking-widest uppercase font-medium mb-1">
              Joining the cohort takes less than 2 minutes
            </h2>

            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Sign up to join the cohort
            </h1>
            <h2 className="text-sm text-gray-700 font-medium mb-1">
              (Payments secured by Stripe)
            </h2>
          </div>
        ) : (
          <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4 text-center">
            Sign Up
          </h1>
        )}
        <AuthContainer>
          <SignUpWithGoogle cohort={this.state.cohort} />
          <SignUpWithGithub cohort={this.state.cohort} />
          <Button
            onClick={() => {
              this.setState({ showForm: true });
              window.analytics.track("Click", {
                title: "Sign Up with Email",
                location: "Registration",
                page: window.location.pathname,
                pageTitle: document.title,
              });
            }}
          >
            Email
          </Button>
          {showForm && (
            <div>
              <input
                value={username}
                onChange={(event) =>
                  this.setState(
                    updateByPropertyName("username", event.target.value)
                  )
                }
                type="text"
                placeholder="Full Name"
                className="input"
              />
              <input
                value={email}
                onChange={(event) =>
                  this.setState(
                    updateByPropertyName("email", event.target.value)
                  )
                }
                type="text"
                placeholder="Email Address"
                className="input"
              />
              <input
                value={passwordOne}
                onChange={(event) =>
                  this.setState(
                    updateByPropertyName("passwordOne", event.target.value)
                  )
                }
                type="password"
                placeholder="Password"
                className="input"
              />
              <input
                value={passwordTwo}
                onChange={(event) =>
                  this.setState(
                    updateByPropertyName("passwordTwo", event.target.value)
                  )
                }
                type="password"
                placeholder="Confirm Password"
                className="input"
              />
              <br />
              <Button
                disabled={isInvalid}
                onClick={() => {
                  this.signUp;
                  window.analytics.track("Click", {
                    title: "Complete Sign Up with Email",
                    location: "Registration",
                    page: window.location.pathname,
                    pageTitle: document.title,
                  });
                }}
              >
                Sign Up
              </Button>

              {error && <p>{error.message}</p>}
            </div>
          )}
          <p className="mt-2 text-sm">
            By signing up to Enlight, you agree to our{" "}
            <Link to="/terms">Terms of Service.</Link>
          </p>{" "}
        </AuthContainer>
      </>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>
);

export default SignUpForm;

export { SignUpLink };
