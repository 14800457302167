import React, { Component } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { createUser, getUserOnboardingData } from "api/db";
import { doSignInWithGoogle } from "api/auth";
import { HOME, ONBOARDING } from "constants/routes";

import Button from "./OAuthButton";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  username: "",
  email: "",
  created_at: "",
  error: null,
};

const svg = () => (
  <svg viewBox="0 0 48 48">
    <defs>
      <path
        id="a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="b">
      <use href="#a" overflow="visible" />
    </clipPath>
    <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
    <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
    <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
    <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
  </svg>
);

class SignInWithGoogle extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.state.created_at = Date.now();
  }

  googleLogin = (event) => {
    const { username, email, created_at } = this.state;

    const { history } = this.props;

    doSignInWithGoogle()
      .then((result) => {
        const user = result.user;
        let photoURL = user.photoURL;
        if (photoURL === undefined) {
          photoURL = "/img/avatar.png";
        }
        createUser(user.uid, user.displayName, user.email, created_at, photoURL)
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }));
            if (!this.props.cohort) {
              navigate(HOME);
              // getUserOnboardingData(user.uid).then(snapshot => {
              //   if (snapshot.val() != null) {
              //     if (
              //       snapshot.val().interest != null &&
              //       snapshot.val().motivation != null &&
              //       snapshot.val().experience != null
              //     ) {
              //       navigate(HOME);
              //     } else {
              //       navigate(ONBOARDING);
              //     }
              //   } else {
              //     navigate(ONBOARDING);
              //   }
              // });
            } else {
              if (typeof window != undefined) {
                window.location.reload(false);
              }
            }
          })
          .catch((error) => {
            this.setState(updateByPropertyName("error", error));
          });
      })
      .catch((error) => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    return (
      <div
        onClick={this.googleLogin}
        className="bg-blue-600 text-gray-100 hover:text-white hover:opacity-75 shadow font-bold text-sm py-3 px-2 rounded flex justify-start items-center cursor-pointer"
      >
        <svg
          viewBox="0 0 24 24"
          className="fill-current mr-3 w-6 h-5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z" />
        </svg>
        <span className="border-l border-blue-500 h-6 w-1 block"></span>
        <span className="pl-1">Continue with Google</span>
      </div>
    );
  }
}

export default SignInWithGoogle;
